<nz-spin [nzSpinning]="isSpinning" [nzDelay]="500">
  <div class="container-fluid">
    <div class="row">
      <h2>Welcome To SY-STORE.COM Store</h2>
    </div>
    <br>
    <div class="row">
      <h5>Please enter your email below</h5>
    </div>
    <div class="row code-section" style="margin-bottom: 65px;">      
      <div class="col-md-4 col-sm-12"></div>
      <div class="col-md-4 col-sm-12">

        <form nz-form class="ant-advanced-search-form" [formGroup]="validateForm"  (ngSubmit)="submit()">
          <div nz-row [nzGutter]="24">
            <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
              <nz-form-item>
                <nz-form-control nzHasFeedback [nzErrorTip]="emailErrorTpl">
                  <input nz-input formControlName="email" [(ngModel)]="email"
                    placeholder="Email" />
                  <ng-template #emailErrorTpl let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      The Email is required!
                    </ng-container>
                    <ng-container *ngIf="control.hasError('email')">
                      The Email is not valid!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-row class="note-div">
            <h5>Note!</h5>
            <p style="color: black;">Please make sure of the e-mail you wrote, becuase important product information such as the licence key
              will be sent to your email.</p>
          </div>
          
        <div nz-col [nzSpan]="20">
          <nz-form-item>
            <nz-form-control>
              <button nz-button nzType="primary sign" [nzSize]="50" nzShape="round">Submit</button>
            </nz-form-control>
          </nz-form-item>
        </div>
        </form>
      </div>    
      <div class="col-md-4 col-sm-12"></div>
    </div>
  </div>
</nz-spin>