<div class="container-fluid">
    <div class="row">
        <h4>Welcome To SY-STORE.COM</h4>
    </div>
    <div class="row">
        <nz-result style="color: white !important;"  *ngIf="!product.autoSentLicenseKey || product.key == null" nzStatus="success" [nzTitle]="msgNotAutoTitle"
            [nzSubTitle]="msgNotAutoDesc">
        </nz-result>
        <nz-result  *ngIf="product.autoSentLicenseKey && product.key != null" nzStatus="success" [nzTitle]="msgAutoTitle"
            [nzSubTitle]="msgAutoDesc">
            <div *ngIf="product.autoSentLicenseKey" nz-result-extra>
                <span class="header-title"><i nz-icon="" nztheme="fill" style="
                    font-size: 20px;margin: 1% 2%;" ng-reflect-nz-theme="fill" ng-reflect-nz-type="check-circle" class="anticon anticon-check-circle ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="check-circle" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg></i>{{product.name}}</span>
              </div>
        </nz-result>
    </div>
    
    <div class="row parent-result-container">
        <div class="result-container">
            <div class="result-inner">
                <div *ngIf="product.autoSentLicenseKey && product.key != null" class="result-header">
                    <span class="key-title"> Your Activation Code: </span> <br> <br> <span class="key-value">{{product.key}}</span>  <br> <br> 
                </div>
                <div *ngIf="!product.autoSentLicenseKey || product.key == null" class="result-header">
                    <span class="header-title"><i nz-icon="" nztheme="fill" style="
                        font-size: 20px;margin: 1% 2%;" ng-reflect-nz-theme="fill" ng-reflect-nz-type="check-circle" class="anticon anticon-check-circle ng-star-inserted"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="check-circle" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg></i>{{product.name}}</span>
                </div>
                <nz-divider></nz-divider>
                <div class="result-content">
                    <div nz-row>
                        <div nz-col [nzSpan]="18" [nzPush]="6">
                            {{product.brand.name}}
                        </div>
                        <div nz-col [nzSpan]="6" [nzPull]="18">
                            <span> Brand</span>
                        </div>
                    </div>
                    <nz-divider></nz-divider>
                    <div nz-row>
                        <div nz-col [nzSpan]="18" [nzPush]="6">
                            {{product.licenceType.name}}
                        </div>
                        <div nz-col [nzSpan]="6" [nzPull]="18">
                           Licence Type
                        </div>
                    </div>
                    <nz-divider></nz-divider>
                    <div nz-row>
                        <div nz-col [nzSpan]="18" [nzPush]="6">
                            {{product.platform.name}}
                        </div>
                        <div nz-col [nzSpan]="6" [nzPull]="18">
                            Platform
                        </div>
                    </div>
                    <nz-divider></nz-divider>
                    <div nz-row>
                        <div nz-col [nzSpan]="18" [nzPush]="6">
                           <a [href]="product.howToActivate" target="_blank"> {{product.howToActivate}}</a>
                        </div>
                        <div nz-col [nzSpan]="6" [nzPull]="18">
                            How To Activate
                        </div>
                    </div>
                    <nz-divider></nz-divider>
                    <div *ngIf="product.autoSentLicenseKey && product.key != null" nz-row>
                        <div nz-col [nzSpan]="18" [nzPush]="6">
                          <a [href]="product.downloadUrl" target="_blank"> {{product.downloadUrl}}</a>
                        </div>
                        <div nz-col [nzSpan]="6" [nzPull]="18">
                             Download Url
                        </div>
                    </div>
                    <nz-divider></nz-divider>
                    <div nz-row>
                        <div nz-col   [nzSpan]="18" [nzPush]="6">
                            {{product.description}}
                        </div>
                        <div nz-col [nzSpan]="6" [nzPull]="18">
                            Description
                        </div>
                    </div>
                    <nz-divider></nz-divider>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-5">
        <button nz-button class="backToHome" [nzType]="'primary'" nzShape="round" [routerLink]="['/home']">
            Home page
          </button>
</div>
</div>