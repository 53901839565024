import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Base } from '../base/base';
import { AccountService, CustomersService, LocalizationService } from '../services/code-service.module';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.less']
})
export class SiteLayoutComponent extends Base{
  isCollapsed = false;
  isLogined = false;
  
  userId: string;
  currentUser: import("src/app/services/code-service.module").User;
  showControlPanel: boolean;
  dropdownShow: boolean = false;
  /**
   *
   */
  constructor(public _router: Router, public _accService:AccountService,public customerService : CustomersService, 
    public locService:LocalizationService ) {
   super(_router,_accService,locService, customerService);
      
  }
  showDropdown(){
    if(this.dropdownShow)
      this.dropdownShow = true;
      else
      this.dropdownShow = false;
  }
  changeLanguage(){
    var dir = localStorage.getItem("dir");
    localStorage.removeItem("dir");
    if(dir == null || dir == '' || dir == 'ltr')
    localStorage.setItem("dir","rtl");
    else
    localStorage.setItem("dir","ltr");
    window.location.reload();
  }
  /**
   *
   */
}
