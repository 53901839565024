import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../services/code-service.module';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.less']
})
export class AdminLayoutComponent {
  isCollapsed = false;
  isLogined = false;
  userId: string;
  currentUser: import("src/app/services/code-service.module").User;
  /**
   *
   */
  constructor(private _router: Router, private _accService:AccountService) {
    debugger;
    var t = _router.url;
    this.userId = localStorage.getItem("userToken");
    if(this.userId != null && this.userId != ''){
      _accService.getUser(this.userId)
      .subscribe(result => {
        if(result != null){
          debugger;
          this.currentUser = result;
          this.isLogined = true;
        }
      });
    }
    
   // this._router.navigate(["/home"]);
  }
  logout(){
    localStorage.setItem("userToken","");
    this.isLogined = false;
    
    this._router.navigate(["/home"]);
  }
}
