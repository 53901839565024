
    <!-- <nz-layout> -->
      <!-- <nz-header>
        <div class="row">
          <div class="col-1">
  
            <a [routerLink]="['/home']">Logo</a>
          </div>
          <div class="col-1">
  
            <a href="#">Arabic</a>
          </div>
          <div class="col-7">
            <a href="#" style="margin:0 21%;">
              Redeem your software or your game from Sy-Store</a>    
          </div>
          <div class="col-3">
            <a href="#">How To Active</a>
            <a href="#">FAQ</a>
          </div>
        </div>
        
      </nz-header> -->
      <!-- <nz-header>
        <div class="logo"><span></span></div>
        <ul nz-menu nzTheme="dark" nzMode="horizontal">
          <li nz-menu-item><a href="#">Arabic</a></li>
          <li nz-menu-item><div class="title"><a [routerLink]="['/home']">Redeem your software or your game from Sy-Store</a></div></li>
         <li nz-menu-item><a href="#"></a></li>
          <li nz-menu-item><a href="#">FAQ</a></li>
        </ul>
      </nz-header> -->
       <!-- <nz-content>
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
    </nz-layout> -->
    <div [dir]="dir" class="margin-bt-100" [ngClass]="{'right' : dir == 'rtl'}">
      <div *ngIf="dropdownShow" class="dropdown">
        <a [routerLink]="['/home']" class="home-link-mobile">
          <div class="navbar-logo-mobile"><img src="assets/logo.png" width="200" height="25" alt=""></div>
          
        </a>
        <ul>
                  <li>
                    <a class="navbar-link" (click)="changeLanguage()">
                      {{ localize('ChangeLanguage','العربية')}}
                    </a>
                  </li>
                  <li>
                    <a class="navbar-link" [routerLink]="['/home/faq']">
                      {{ localize('FAQ','FAQ')}}
                    </a>
                  </li>
                </ul>
              </div>
    <header id="navbar">
      <nav class="navbar-container container">
        <a [routerLink]="['/home']" class="home-link">
          <div class="navbar-logo"><img src="assets/logo.png" width="200" height="25" alt=""></div>
          
        </a>
        <div class="title-link"><h5>{{ localize('RedeemYourSoftwareOrGamefromSYSTORECOM','Redeem your Software or Gamefrom SY-Store')}}
        </h5></div>
        <button type="button" class="navbar-toggle" aria-label="Open navigation menu" (click)="showDropdown()">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>
        

        <div class="navbar-menu detached">
          <ul class="navbar-links">
            <li class="navbar-item"><a class="navbar-link" (click)="changeLanguage()">{{ localize('ChangeLanguage','العربية')}}</a></li>
            <li class="navbar-item"><a class="navbar-link" [routerLink]="['/home/faq']">{{ localize('FAQ','FAQ')}}</a></li>
          </ul>
        </div>
      </nav>
  </header>
  <main>
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </main>
  <div class="footer">
    <div>
      <span>{{ localize('IfThereAnyProblemThenContactUsViaEmail','If there any problem, then contact us via email')}}</span> <span class="email">support@sy-store.com</span>
    </div>
  </div>
</div>