import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductTypesService, ProductOutputDto, AccountService, CustomersService, LocalizationService } from 'src/app/services/code-service.module';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'nz-activated-code',
  templateUrl: './activated-code.component.html',
  styleUrls: ['./activated-code.component.less']
})
export class ActivatedCodeComponent implements OnInit {
  id: number;
  success: boolean;
  product: ProductOutputDto = new ProductOutputDto();
  msgNotAutoTitle:string = 'Thank you! Activation was successfull';
  msgNotAutoDesc:string = 'You will recieve the download link and the activation code within 1 up to 6 hours maximum to your email.';
  msgAutoTitle:string = 'Thank you! Activation was successfull';
  msgAutoDesc:string = 'You will find the activation method and the download link in your email or the link below.';

  /**
   *
   */
  constructor(public _router: Router, private fb: FormBuilder, private route: ActivatedRoute,
    public _customersService: CustomersService, public accService: AccountService,
    public locService: LocalizationService, private productService: ProductTypesService) {
    this.id = +this.route.snapshot.paramMap.get('id');
  }
  ngOnInit(): void {
    var code = localStorage.getItem("code");
    this.productService.getProductType(this.id, code)
      .subscribe(result => {
        if (result != null && result.id != null) {
          this.success = true;
          this.product = result;
        }
      });
  }
}