import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { InputLoginDto, AccountService } from 'src/app/services/code-service.module';

@Component({
  selector: 'ex-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit{
  validateForm: FormGroup;
  loginDto: InputLoginDto = new InputLoginDto();
  success: boolean;
  

  constructor(private _router: Router,private fb: FormBuilder, private _accountService : AccountService) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true]
    });
  }
  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    console.log(this.loginDto);
    this._accountService.login(this.loginDto)
          .pipe(finalize(() => { this.success = false; }))
          .subscribe(result => {
            debugger;
            if(result != null){
              console.log(result);
              localStorage.setItem("userToken",result);
              this.success = true;
              this._router.navigate(["/home"]);
              window.location.reload;
            }
          });
  }
}