import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgZorroAntdModule, NZ_I18N, en_US, NzCarouselModule,NzAutocompleteModule, NzMessageService, NzDropDownModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { LoginComponent } from './pages/account/login.component';
import { ForgetPasswordComponent } from './pages/account/forget-password.component';
import { AccountService, CustomersService, LocalizationService, ProductTypesService, RedeemCodesService } from './services/code-service.module';
import { SiteLayoutComponent } from './site-layout/site-layout.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AuthGuard } from './auth/auth.guard';
import { QRCodeComponent } from './pages/qrcode/qrcode.component';
import { ActivatedCodeComponent } from './pages/qrcode/activate-code/activated-code.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    QRCodeComponent,
    ActivatedCodeComponent,
    ForgetPasswordComponent,SiteLayoutComponent,AdminLayoutComponent
  ],
  imports: [ 
    ReactiveFormsModule, 
    BrowserModule,
    NzDropDownModule,
    AppRoutingModule,
    NgZorroAntdModule,
    NzCarouselModule,
    FormsModule,
    NzAutocompleteModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    CustomersService,
  NzMessageService,
  RedeemCodesService,
  ProductTypesService,
  LocalizationService,
  AccountService,
    AuthGuard,
    { 
    provide: NZ_I18N, useValue: en_US
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
