<div class="container pt-5">
    <div class="row pt-5">
        <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12"></div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div class="padding-forgot-form">
                <form nz-form [formGroup]="validateForm" class="forgot-form" (ngSubmit)="submitForm()">
                    <nz-form-item>
                      <nz-form-control nzErrorTip="Please type your Email!">
                        <nz-input-group nzPrefixIcon="mail">
                          <input type="text" nz-input formControlName="email" placeholder="Email" />
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-control>
                        <a class="login-form" class="login-form" [routerLink]="['/login']">Back to login</a>
                        <button nz-button class="login-form-button" [nzType]="'primary'">Get Key</button>
                        <!-- Or
                        <a>register now!</a> -->
                      </nz-form-control>
                    </nz-form-item>
                  </form>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12"></div>
    </div>

</div>