import { Component, OnInit } from '@angular/core';
import { RedeemCodesService, CheckInputDto, User } from 'src/app/services/code-service.module';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'nz-qr-table-selector',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.less']
})
export class QRCodeComponent implements OnInit {
  
  validateForm: FormGroup;
  success: boolean;
  codeInput: CheckInputDto = new CheckInputDto();
  textCode:string;
  isSpinning = false;
  notSame: boolean;
  email:string;
  /**
   *
   */
  constructor(public _router: Router, 
    private fb : FormBuilder,
    private route: ActivatedRoute,
    private _codeService: RedeemCodesService,
    private modalService: NzModalService,
    private nzMessageService: NzMessageService) {

  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log(params); 
        var code=""; 
        for (var i = 0; i < params.code.length; i=i+2) {
          var ar = []; 
          ar.push(params.code[i] + params.code[i+1]); 
          code += String.fromCharCode.apply(String, ar);
          }
          console.log(code);
        this.textCode = code;
      }
    );
    this.validateForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }
  
  submit(): void {
  var valid = true;
  for (const i in this.validateForm.controls) {
    this.validateForm.controls[i].markAsDirty();
    this.validateForm.controls[i].updateValueAndValidity();
    if (!this.validateForm.controls[i].valid)
      valid = false;
  }
  if (valid) {
  this.isSpinning = true;
  this.codeInput = new CheckInputDto({ code: this.textCode, email: this.email});
  localStorage.setItem("code", this.textCode);
  this._codeService.checkCodeFromQR(this.codeInput)
  .subscribe(result => {
    if (result != null) {
      switch (result) {
        case -2:
          this.success = false;
          this.nzMessageService.create('error', `An Error Has Occur`);
          break;
        case -1:
          this.success = false;
          this.nzMessageService.create('error', `Your Code Has Activated Before.`);
          break;
        case 0:
          this.success = false;
          this.nzMessageService.create('error', `Wrong Code`);
          break;
        case -5:
          this.success = false;
          this.modalService.info({
            nzTitle: 'The code is block',
            nzContent: '<div dir="ltr"><p>Sorry, this code has been blocked for one of the following reasons:</p>' +
              '<p>1. The code has already been deactivated by technical support</p>' +
              '<p>2. The code has been stolen and sold informally</p>' +
              '<p>To find out more about the problem you are facing, please send an email to support@sy-store.com</p>' +
              '<p>containing The activation code behind the card which composed from 20-digit</p>' +
              '<p>We will contact you as soon as possible.</p></div>' +
              '<div dir="rtl"><p>نرجوا المعذرة لقد تم حظر هذا الرمز لاحد الاسباب التالية:</p>' +
              '<p>1.	من الممكن ان قد تم بالفعل تفعيل هذا الرمز من قبل عن طريق الدعم الفني</p>' +
              '<p>2.	من الممكن ان قد تم سرقة هذه البطاقة وبيعت بشكل غير رسمي</p>' +
              '<p>لمعرفة المزيد حول هذه المشكلة التي تواجهك، يرجى الاتصال بنا على البريد التالي support@sy-store.com</p>' +
              '<p> وإرسال رمز التفعيل الموجود خلف البطاقة المكون من 20 رقم</p>' +
              '<p>وسيتم التواصل معك في أقرب وقت ممكن</p></div>',
            nzOnOk: () => console.log('Info OK')
          });
          break;
        default:
          break;
      }
      this.isSpinning = false;
      if (result > 0) {
        this.success = true;
        this.nzMessageService.create('success', `Your Code Status Is Waiting, Please Check Your Email`);
        this._router.navigate(["/home"]);
        this._router.navigate(["/qrcode/key/" + result]);
      }
    }
  });
}
}
}