import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from './services/code-service.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  isIEOrEdge:boolean;
  space= "    ";
  browsersList: any[] = [
    {
      title:'Firefox Browser',
      url:'https://www.mozilla.org/en-US/firefox/new/'
    },
    {
      title:'Google Chrome',
      url:'https://www.google.com/chrome/'
    },
    {
      title:'Microsoft Edge',
      url:'https://www.microsoft.com/en-us/edge'
    },
    {
      title:'Opera Browser',
      url:'https://www.opera.com/browsers/opera'
    },
    {
      title:'Safari Browser',
      url:'https://safari.en.softonic.com/'
    }
  ]
  msgForChange = `
  To solve this problem, please update the browser you are using or download the last version from one of the following browsers:`;
  msgForChangeArabic = `
  
  لحل هذه المشكلة يرجى تحديث المتصفح الذي تستخدمه او قم بتحميل نسخة محدثة من أحد المتصفحات التالية:`;
  msgOOPS = "Unfortunately, you can not access the site because the browser you are using is very old.";
  msgOOPSArabic = "للأسف, لا يمكنك الدخول الى هذا الموقع لان المتصفح الذي تستخدمه قديم جدا";
  ngOnInit(): void {
    // var engine = window.navigator.userAgent;
    // this.isIEOrEdge = !/msie\s|trident\/|edge\//i.test(engine);
    var isIE = localStorage.getItem("isIE");
    this.isIEOrEdge = isIE != null &&  isIE != undefined && isIE == 'true';
  }
  
  
}
