import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/account/login.component';
import { ForgetPasswordComponent } from './pages/account/forget-password.component';
import { SiteLayoutComponent } from './site-layout/site-layout.component';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AuthGuard } from './auth/auth.guard';
import { QRCodeComponent } from './pages/qrcode/qrcode.component';
import { ActivatedCodeComponent } from './pages/qrcode/activate-code/activated-code.component';
const routes: Routes = [
  // Site routes goes here 
  { 
    path: '', 
    component: SiteLayoutComponent,
    children: [
      { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m=> m.HomeModule) },
      //{ path: 'about', component: AboutComponent }
    ]
},

// App routes goes here
{ 
    path: '',
    component: AdminLayoutComponent, 
    children: [
      { path: 'constants', loadChildren: () => import('./pages/constants/constants.module').then(m => m.ConstantsModule) },
      { path: 'redeem-code', loadChildren: () => import('./pages/redeem-code/redeem-code.module').then(m => m.RedeemCodeModule) },
      { path: 'customers', loadChildren: () => import('./pages/customers/customer.module').then(m => m.CustomerModule) },
      { path: 'users', loadChildren: () => import('./pages/users/user.module').then(m => m.UserModule) },
      { path: 'questions', loadChildren: () => import('./pages/questions/question.module').then(m => m.QuestionModule) },
    ],
    canActivate: [AuthGuard]
},

// no layout routes
{ path: 'login', component: LoginComponent,canActivate: [AuthGuard] },
{ path: 'qrcode', component: QRCodeComponent },
{ path: 'qrcode/key/:id', component: ActivatedCodeComponent },

{ path: 'forgot-password', component: ForgetPasswordComponent,canActivate: [AuthGuard] },
// otherwise redirect to home
{ path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
