import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../services/code-service.module';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private _accService: AccountService) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      debugger;
      if(localStorage.length>0 && localStorage.getItem("userToken")!=null && localStorage.getItem("IsAdmin")=="true")
        return true;
      this.router.navigate(['/home']);
      window.location.reload();
      return false;
    let userId = localStorage.getItem("userToken");
    if (userId != null && userId != '') {
      debugger;
      this._accService.getUser(userId)
        .subscribe(result => {
          if (result != null) {
            debugger;
            if (result.isAdmin)
              return true;
            this.router.navigate(['/home']);
            window.location.reload();
            return false;
          }
          this.router.navigate(['/home']);
          window.location.reload();
          return false;
        });
        this.router.navigate(['/home']);
        window.location.reload();
        return false;
    }
  }
}
