<div class="container pt-5">
    <div class="row pt-5">
        <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12"></div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div class="padding-login-form">
                <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
                    <nz-form-item>
                      <nz-form-control nzErrorTip="Please input your email!">
                        <nz-input-group nzPrefixIcon="mail">
                          <input type="text" nz-input formControlName="email" [(ngModel)]="loginDto.email" placeholder="email" />
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-control nzErrorTip="Please input your Password!">
                        <nz-input-group nzPrefixIcon="lock">
                          <input type="password" nz-input formControlName="password" [(ngModel)]="loginDto.password" placeholder="Password" />
                        </nz-input-group>
                      </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                      <nz-form-control>
                        <label nz-checkbox formControlName="remember">
                          <span>Remember me</span>
                        </label>
                        <a class="login-form-forgot" class="login-form-forgot" [routerLink]="['/forgot-password']">Forgot password</a>
                        <button nz-button class="login-form-button" [nzType]="'primary'">Log in</button>
                        <!-- Or
                        <a>New Member!</a> -->
                      </nz-form-control>
                    </nz-form-item>
                  </form>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12"></div>
    </div>

</div>