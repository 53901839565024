import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, CustomerInputDto, CustomersService, LocalizationService, User } from '../services/code-service.module';

@Injectable()
export class Base implements OnInit {
    public userId: string;
    public dir: string;
    public currentUser: User = new User();
    public showControlPanel: boolean;
    public isLogined: boolean;
    public hasWrittingAccess: boolean;
    public isAdmin: boolean;
    public currentCustomer: any;
    public static localizationResources = new Map<string,string>();
  url: any;
    constructor(public _router : Router ,public accService: AccountService, public locService : LocalizationService, public _customersService: CustomersService) {
        this.url = _router.url; 
        this.userId = localStorage.getItem("userToken");
        this.dir = localStorage.getItem("dir");
        localStorage.removeItem("IsAdmin");
        if (this.userId != null && this.userId != '') {
          debugger;
          accService.getUser(this.userId)
            .subscribe(result => {
              if (result != null) {
                this.currentUser = result;
                this.hasWrittingAccess = this.currentUser.hasWrittingAccess;
                this.isAdmin = this.currentUser.isAdmin;
                if (this.currentUser.isAdmin){
                  this.showControlPanel = true;
            localStorage.setItem("IsAdmin","true");
                }
                else{
                  this.showControlPanel = false;
        localStorage.removeItem("IsAdmin");
                }
                this.isLogined = true;
                if(!this.isAdmin)
                this._customersService.getCustomerByUserId(this.userId)
                  .subscribe(result => {
                    if (result != null && result.id != null) {
                      console.log(result);
                      this.currentCustomer = result;
                      this.currentCustomer.fullname = this.currentCustomer.firstName + ' ' + this.currentCustomer.lastName;
                    }
                  });
              }
              if(this.url == "/")
                  this._router.navigate(["/home"]);
            });
        }
        else if(this.url == "/")
        this._router.navigate(["/home"]);
        if (this.dir == undefined || this.dir == '' || this.dir == 'ltr')
          locService.getEnglishResources().subscribe(
            result => {
              if ((result != null && result != undefined) && result.length > 0) {
                  
                Base.localizationResources = new Map<string, string>();
                result.forEach(element => {
                  Base.localizationResources.set(element.key, element.value);
                });
              }
            }
          );
        else
          if (this.dir == 'rtl')
            locService.getArabicResources().subscribe(
              result => {
                if ((result != null && result != undefined) && result.length > 0) {
                    
                  Base.localizationResources = new Map<string, string>();
                  result.forEach(element => {
                    Base.localizationResources.set(element.key, element.value);
                  });
                }
              }
            );
    }
  
    localize(key, val) {
        if (Base.localizationResources.get(key))
        return Base.localizationResources.get(key);
        else return val;
      }
    ngOnInit(): void {
    }
}