
        <router-outlet *ngIf="!isIEOrEdge"></router-outlet>
        <div *ngIf="isIEOrEdge" class="container">
                <div class="row mt-2" style="direction: rtl;">
                        <div class="row ml-5 mr-5" style="
                    font-size: 24px;
                    font-weight: 500;
                    color: red;
                ">
                            {{msgOOPSArabic}}
                    </div>
                    <div class="row mt-5 ml-5 mr-5" style="
                    font-size: 18px;
                    font-weight: 500;
                ">
                            {{msgForChangeArabic}}
                    </div>
                    
                </div>
                <div class="row mt-2" style="direction: ltr;">
                    
                <div class="row mt-2 pt-2">
                        <div class="row ml-5 mr-5" style="
                    font-size: 24px;
                    font-weight: 500;
                    color: red;
                ">
                            {{msgOOPS}}
                    </div>
                    <div class="row mt-5 ml-5 mr-5" style="
                    font-size: 18px;
                    font-weight: 500;
                ">
                            {{msgForChange}}
                    </div>
                </div>
                    <div
                    *ngFor="let br of browsersList"
                    class="col-12 ml-5 mr-5" style="
                    font-size: 18px;
                    font-weight: 500;
                ">
                
                <br>
                        <p style="float: left;">
                                {{br.title}}  &nbsp; &nbsp; &nbsp;   {{br.url}} 
                        </p>
                    </div>
                </div>
        </div>