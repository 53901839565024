<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null">
    <div class="sidebar-logo">
      <a [routerLink]="['/home']"  target="_blank">
        <img src="assets/logo.png" alt="logo">
        <h1>Code Generator</h1>
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-submenu nzTitle="Components" nzIcon="team">
<ul>
  <li nz-menu-item  routerLink="/users">Users
  </li>
  <li nz-menu-item  routerLink="/questions"> Questions</li>
  <li nz-menu-item  routerLink="/redeem-code">Redeem Code
  </li>
  <li nz-menu-item routerLink="/customers">Customers
  </li>
</ul>
      </li>
      
      <li nz-submenu nzTitle="Constants" nzIcon="database">
        <ul>
          <li nz-menu-item routerLink="/constants/licence-type">Licence Type</li>
          <li nz-menu-item routerLink="/constants/platform">Platform</li>
          <li nz-menu-item routerLink="/constants/brand">Brand</li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
          <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
        </span>
        <span class="span-login"> <div class="login-section">
          <a *ngIf="isLogined" (click)="logout()">Logout</a>
          <a *ngIf="!isLogined" [routerLink]="['/login']">Login</a>
        </div>
         </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>